<template>
    <div class="view pa24">
        <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList"><i
                class="el-icon-office-building fs12 mr5"></i> <span class="fs13">{{ checkedCompany.companyName }}</span>
            <i class="el-icon-caret-bottom  fs12 ml5"></i>
        </div>
        <div>
            <el-tabs v-model="year" @tab-click="searchFun">
                <el-tab-pane label="全部" name="all"></el-tab-pane>
                <el-tab-pane :label="String(y) + '年'" :name="String(y)" v-for="(y, index) in yearData"
                    :key="index"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="d-flex mt20">
            <div>
                <el-input class="w400 mr10" placeholder="请输入研发项目名称" v-model="searchData.name" @change="searchFun" />
                <!-- <el-button type="primary" @click="searchFun">搜索</el-button> -->
            </div>
            <div style="margin-left: auto" class="d-flex">
                <el-button-group class="mr10">
                    <el-button :loading="loadingBtn" type="primary" :disabled="!checkedCompany.pcId"
                        @click="outPutProjectData()">导出项目</el-button>
                    <el-button type="primary" :disabled="!checkedCompany.pcId"
                        @click="showImport = true">导入项目</el-button>
                </el-button-group>
                <el-badge :value="draftNumber" class="item mr10" v-if="draftNumber>0">
                    <el-button type="success" :disabled="!checkedCompany.pcId" @click="showDraftFun()">草稿箱</el-button>
                </el-badge>
                <el-button type="success" v-else :disabled="!checkedCompany.pcId" @click="showDraftFun()">草稿箱</el-button>
                <el-button type="primary" class="wordBtn" :disabled="!checkedCompany.pcId"
                    @click="showAddModel">新增项目</el-button>
                <el-button type="success" icon="el-icon-refresh-right" :disabled="!checkedCompany.pcId || year == 'all'"
                    @click="updateData()" title="请选择年份更新预算"></el-button>
            </div>
        </div>
        <!--  class="tableRadio" -->
        <commonTable :tableData="tableData" :pageSize="50" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column prop="projectNo" align="center" label="项目编号" width="80" />
                <el-table-column prop="projectDay" align="center" label="年份" show-overflow-tooltip width="60">
                    <template slot-scope="scope">
                        {{ scope.row.projectDay ? scope.row.projectDay.slice(0, 4) : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="projectName" label="项目名称" show-overflow-tooltip min-width="150">
                    <template slot-scope="scope">
                        <div @click="toCreateWord(scope.row)" class="curPointer">
                            <div v-if="!scope.row.isWrite" :class="{ 'e6a23c': !scope.row.isWrite }"><i
                                    class="el-icon-warning"></i> {{ scope.row.projectName
                                }}</div>
                            <div v-else class="cblue">{{ scope.row.projectName }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="money" align="center" label="研发类型" show-overflow-tooltip width="80">
                    <template slot-scope="scope">
                        {{ projectTypeObj[scope.row.projectType] }}
                    </template>
                </el-table-column>
                <el-table-column prop="money" align="center" label="预算金额(万)" show-overflow-tooltip width="100">
                    <template slot-scope="scope">
                        {{ scope.row.budget ? scope.row.budget : scope.row.money ? scope.row.money : '0' }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="研发起止时间" show-overflow-tooltip width="190">
                    <template slot-scope="scope">
                        {{ scope.row.startDay && scope.row.endDay ? scope.row.startDay + ' 至 ' + scope.row.endDay : '-'
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="headName" align="center" label="项目负责人" show-overflow-tooltip width="100">
                    <template slot-scope="scope">
                        {{ scope.row.headName ? scope.row.headName : '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="proposerName" align="center" label="申请人" show-overflow-tooltip width="80">
                    <template slot-scope="scope">
                        {{ scope.row.proposerName ? scope.row.proposerName : '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="job" align="center" label="项目组成员" show-overflow-tooltip min-width="150">
                    <template slot-scope="scope">
                        <div class="d-flex" v-if="scope.row.projectUserList && scope.row.projectUserList.length > 0">
                            <div class="ma5" v-for="(item, key) in scope.row.projectUserList" :key="key">
                                {{ item.userName }};</div>
                        </div>
                        <div v-else>
                            暂无成员数据
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="testName" align="center" label="检测或测试人" show-overflow-tooltip width="120">

                    <template slot-scope="scope">
                        {{ scope.row.testName ? scope.row.testName : "-" }}
                    </template>
                </el-table-column>
                <el-table-column prop="testName" align="center" label="项目状态" show-overflow-tooltip width="140">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.isDrafts" @change="updateState(scope.row, true)" active-text="正式"
                            inactive-text="草稿" :active-value="false" :inactive-value="true">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" @click.stop="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button type="text" @click="toCreateWord(scope.row)">生产内容</el-button>
                        <el-button style="color:#F56C6C" type="text" @click.stop="delProject(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>

        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="80%" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="100px">
                <el-row>
                    <el-col :md="8">
                        <el-form-item label="项目名称" prop="projectName">
                            <el-input v-model="formData.projectName" placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8">
                        <el-form-item label="项目编号" prop="projectNo">
                            <el-input v-model="formData.projectNo" placeholder="请输入项目编号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8">
                        <el-form-item label="所属部门" prop="departmentId">
                            <el-select class="w100p" v-model="formData.departmentId" clearable placeholder="请选择所属部门">
                                <el-option v-for="item in departmentData" :key="item.departmentId"
                                    :label="item.departmentName" :value="item.departmentId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="8">
                        <el-form-item label="项目来源" prop="projectFrom">
                            <el-input v-model="formData.projectFrom" placeholder="请输入项目来源"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8">
                        <el-form-item label="经费来源" prop="moneyFrom">
                            <el-input v-model="formData.moneyFrom" placeholder="请输入经费来源"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="8">
                        <el-form-item label="研发费预算" prop="budget" title="该预算用于预算显示">
                            <el-input placeholder="请输入研发费预算" class="suffixInput" v-model="formData.budget"
                                type="number">
                                <template slot="append">万元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8">
                        <el-form-item label="预分配金额" prop="money" title="该预算用于计算">
                            <el-input placeholder="请输入预分配金额" class="suffixInput" v-model="formData.money" type="number">
                                <template slot="append">万元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="8">
                        <el-form-item label="立项日期" prop="projectDay">
                            <el-date-picker type="date" placeholder="关联研发日期，日期范围为6个月" value-format="yyyy-MM-dd"
                                v-model="formData.projectDay" @change="changePDay"
                                style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :md="16">
                        <el-form-item label="研发日期" style="margin:0" required>
                            <div class="flex-a-c">
                                <el-form-item prop="startDay" style="width: calc(50% - 25px)">
                                    <el-date-picker class="w100p" value-format="yyyy-MM-dd" type="date"
                                        placeholder="选择起始日期" v-model="formData.startDay"
                                        @change="changeTime();"></el-date-picker>
                                </el-form-item>
                                <div class="w50 textc" style="margin-bottom: 18px;">至</div>
                                <el-form-item prop="endDay" value-format="yyyy-MM-dd" style="width: calc(50% - 25px)">
                                    <el-date-picker class="w100p" type="date" placeholder="选择结束日期"
                                        v-model="formData.endDay" @change="changeTime();"></el-date-picker>
                                </el-form-item>
                                <!-- <el-date-picker class="w100p" unlink-panels v-model="dayValue" value-format="yyyy-MM-dd"
                                    type="daterange" range-separator="至" start-placeholder="选择起始日期"
                                    end-placeholder="选择结束日期">
                                </el-date-picker> -->
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="8">
                        <el-form-item label="项目负责人" prop="head">
                            <el-select class="w100p" v-model="formData.head" filterable remote clearable
                                placeholder="输入名称或岗位关键词，选择负责人" :remote-method="getHeadUser" :loading="headLoading"
                                :disabled="!formData.startDay || !formData.endDay"
                                @change="checkedUserFun($event, 'head')">
                                <el-option v-for="(row, id) in headUserData" :key="id" :label="row.userName"
                                    :value="row.puId">
                                    <span style="float: left">{{ row.userName }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ row.work }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8">
                        <el-form-item label="申请人" prop="proposer">
                            <el-select class="w100p" v-model="formData.proposer" filterable remote clearable
                                placeholder="输入名称或岗位关键词，选择申请人" :remote-method="getProposerUserData"
                                @change="checkedUserFun($event, 'proposer')"
                                :disabled="!formData.startDay || !formData.endDay" :loading="proposerLoading">
                                <el-option v-for="(row, id) in proposerUserData" :key="id" :label="row.userName"
                                    :value="row.puId">
                                    <span style="float: left">{{ row.userName }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ row.work }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8">
                        <el-form-item label="检测人员" prop="test">
                            <el-select class="w100p" v-model="formData.test" filterable remote clearable
                                :disabled="!formData.startDay || !formData.endDay" placeholder="输入名称或岗位关键词，选择测试员"
                                @change="checkedUserFun($event, 'test')" :remote-method="getTestUser"
                                :loading="testLoading">
                                <el-option v-for="(row, id) in testUserData" :key="id" :label="row.userName"
                                    :value="row.puId">
                                    <span style="float: left">{{ row.userName }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ row.work }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="8">
                        <el-form-item label="项目类型" prop="projectType">
                            <el-select class="w100p" v-model="formData.projectType" clearable placeholder="请选择项目类型">
                                <el-option v-for="item in projectTypeData" :key="item.id" :label="item.name"
                                    :value="String(item.id)">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8">
                        <el-form-item label="项目状态" prop="isDrafts">
                            <el-switch v-model="formData.isDrafts" active-text="正式" inactive-text="草稿"
                                :active-value="false" :inactive-value="true">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <div class="ml100 mb10">
                    <div class="d-flex">
                        <div class="flex-c-c mr20">
                            <div class="bgbule w20 h20 mr10 br3"></div>
                            <div>原项目组人员</div>
                        </div>
                        <div class="flex-c-c">
                            <div class="baffca96 w20 h20 mr10 br3"></div>
                            <div>申请表新增人员</div>
                        </div>
                    </div>
                </div> -->
                <el-form-item label="项目组人员">
                    <div class="flex-a-b-c"><el-input v-model="userNameArr" type="text" style="width:calc(100% - 170px)"
                            placeholder="快捷添加人员，输入人员名称使用（、，）分隔进行快捷添加（必须是列表列表显示的人才能添加）"></el-input>
                        <el-button @click="addUserFun" class="ml10" type="primary"
                            :disabled="userTableData.length <= 0">快捷添加</el-button>
                        <el-button @click="emptyUser" type="danger" :disabled="userTableData.length <= 0">清
                            空</el-button>
                    </div>
                    <div v-if="checkedUser.length == 0" class="cA3">请选择人员</div>
                    <div v-else><el-tag class="mr10" v-for="(row, index) in checkedUser" @close="delCheckedUser(index)"
                            size="medium" :key="index" closable :type="tagStyleData[row.puId % 3]">
                            {{ row.userName }}
                        </el-tag>
                    </div>
                </el-form-item>
                <div v-if="formData.startDay && formData.endDay">
                    <el-divider content-position="left">
                        <div v-if="checkedUser.length == 0">请选择项目组人员</div>
                        <div v-else>已选中 <span class="fwbold">{{ checkedUser.length }}</span> 位项目组人员</div>
                    </el-divider>
                    <div class="d-flex mt20 mb20">
                        <div>
                            <el-input class="w200 mr10" placeholder="请输入姓名关键字" v-model="userName" />
                            <el-button type="primary" @click="searchUserTable">搜索</el-button>
                        </div>
                    </div>
                    <el-table v-loading="userLoading" ref="multipleTable" :data="userTableData" border lay
                        tooltip-effect="dark" @select="selectUser" @select-all="selectAllFun">
                        <el-table-column type="selection" width="55" />
                        <el-table-column type="index" align="center" label="序号" />
                        <el-table-column prop="userName" align="center" label="姓名" show-overflow-tooltip width="100" />
                        <el-table-column prop="department" align="center" label="部门" show-overflow-tooltip
                            width="100" />
                        <el-table-column prop="work" align="center" label="岗位" show-overflow-tooltip width="120" />
                        <el-table-column prop="education" align="center" label="学历" show-overflow-tooltip width="80" />
                        <el-table-column prop="university" align="center" label="毕业学校" show-overflow-tooltip
                            min-width="200">
                            <template slot-scope="scope">
                                {{ scope.row.university ? scope.row.university : '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="major" align="center" label="专业" show-overflow-tooltip width="150">
                            <template slot-scope="scope">
                                {{ scope.row.major ? scope.row.major : '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="job" align="center" label="职称" show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{ scope.row.job ? scope.row.job : '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="inTime" align="center" label="入职时间" show-overflow-tooltip width="120">
                            <template slot-scope="scope">
                                {{ scope.row.inTime ? scope.row.inTime.slice(0, 10) : "-" }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="outTime" align="center" label="离职时间" show-overflow-tooltip width="120">
                            <template slot-scope="scope">
                                {{ scope.row.outTime ? scope.row.outTime.slice(0, 10) : "-" }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="remark" align="center" label="备注" show-overflow-tooltip width="100">
                            <template slot-scope="scope">
                                {{ scope.row.remark ? scope.row.remark : '-' }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="display: flex; justify-content: flex-end; margin-top: 16px"
                        v-if="userTableData.length !== 0">
                        <el-pagination background @size-change="userHandleSizeChange"
                            @current-change="userHandleCurrentChange" :current-page="userCurrentPage"
                            :page-sizes="[30, 50, 100]" layout="total, sizes, prev, pager, next, jumper" class=""
                            :total="userTotal" :pager-count="5" />
                    </div>
                </div>
                <el-divider content-position="left" v-else>选择研发日期筛选项目组人员</el-divider>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate" :loading="btnLoading">提 交</el-button>
            </span>
        </el-dialog>
        <!-- 草稿箱 -->
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="项目草稿箱" :visible.sync="showDraft"
            :destroy-on-close="true" width="80%" center>
            <div>
                <el-input class="w400 mr10" placeholder="请输入研发项目名称" v-model="draftName" @change="searchDraftList" />
                <!-- <el-button type="primary" @click="searchFun">搜索</el-button> -->
            </div>
            <commonTable :tableData="draftTableData" :loading="draftLoading" @handleSizeChange="draftSizeChange"
                @handleCurrentChange="draftCurrentChange" :currentPage="draftCurrentPage" :total="draftTotal">
                <template v-slot:table>
                    <el-table-column prop="projectNo" align="center" label="项目编号" width="80" />
                    <el-table-column prop="projectDay" align="center" label="年份" show-overflow-tooltip width="60">
                        <template slot-scope="scope">
                            {{ scope.row.projectDay ? scope.row.projectDay.slice(0, 4) : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="projectName" label="项目名称" show-overflow-tooltip min-width="150">
                        <template slot-scope="scope">
                            <div @click="toCreateWord(scope.row)" class="curPointer">
                                <div v-if="!scope.row.isWrite" :class="{ 'e6a23c': !scope.row.isWrite }"><i
                                        class="el-icon-warning"></i> {{ scope.row.projectName
                                    }}</div>
                                <div v-else class="cblue">{{ scope.row.projectName }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="money" align="center" label="研发类型" show-overflow-tooltip width="80">
                        <template slot-scope="scope">
                            {{ projectTypeObj[scope.row.projectType] }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="money" align="center" label="预算金额(万)" show-overflow-tooltip width="100">
                        <template slot-scope="scope">
                            {{ scope.row.budget ? scope.row.budget : scope.row.money ? scope.row.money : '0' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="education" align="center" label="研发起止时间" show-overflow-tooltip width="190">
                        <template slot-scope="scope">
                            {{ scope.row.startDay && scope.row.endDay ? scope.row.startDay + ' 至 ' + scope.row.endDay :
            '-'
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="headName" align="center" label="项目负责人" show-overflow-tooltip width="100">
                        <template slot-scope="scope">
                            {{ scope.row.headName ? scope.row.headName : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="proposerName" align="center" label="申请人" show-overflow-tooltip width="80">
                        <template slot-scope="scope">
                            {{ scope.row.proposerName ? scope.row.proposerName : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="job" align="center" label="项目组成员" show-overflow-tooltip min-width="150">
                        <template slot-scope="scope">
                            <div class="d-flex"
                                v-if="scope.row.projectUserList && scope.row.projectUserList.length > 0">
                                <div class="ma5" v-for="(item, key) in scope.row.projectUserList" :key="key">
                                    {{ item.userName }};</div>
                            </div>
                            <div v-else>
                                暂无成员数据
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="testName" align="center" label="检测或测试人" show-overflow-tooltip width="120">

                        <template slot-scope="scope">
                            {{ scope.row.testName ? scope.row.testName : "-" }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="testName" align="center" label="项目状态" show-overflow-tooltip width="140">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.isDrafts" @change="updateState(scope.row, false)"
                                active-text="正式" inactive-text="草稿" :active-value="false" :inactive-value="true">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" @click.stop="showUpdateModel(scope.row)">编辑</el-button>
                            <el-button type="text" @click="toCreateWord(scope.row)">生产内容</el-button>
                            <el-button style="color:#F56C6C" type="text" @click.stop="delProject(scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </el-dialog>
        <!-- 生成内容 -->
        <project-develop ref="projectDevelop" :year="year" :projectDetails="checkedProject"
            @close="renewList()"></project-develop>
        <!-- 选择研发企业 -->
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
        <!-- 项目导入 -->
        <importModel :data="{ pcId: this.checkedCompany.pcId }" downloadModelType="get"
            :uploadFilesTitle="uploadFilesTitle" :uploadFilesVisible="showImport" :uploadModelUrl="uploadModelUrl"
            :downloadModelFileName="downloadModelFileName" :downloadModelUrl="downloadModelUrl"
            @UploadFilesSuccess="filesSuccess" @uploadFilesDialogClose="hideModel" @onConfirmUploadFiles="searchFun" />
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import projectDevelop from "@/pages/projectAdmin/components/projectDevelop";
import { getProjectDataList, setProjectData, getProjectUserList, getProjectDepartment, outPutProjectData, updateProjectMoneyCount } from "@/api/projectAdmin";
import importModel from "@/components/common/importModel";
export default {
    name: "projectAdmin",
    components: {
        commonTable,
        selectCompanyList,
        projectDevelop,
        importModel,
    },
    data() {
        return {
            checkedCompany: {},
            tagStyleData: ['', 'warning', 'success'],
            //列表配置
            yearData: [],
            year: "all",
            currentPage: 1, //当前页
            pageSize: 50, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            formData: {
                projectNo: "RD",
                projectName: "",
                money: 0,
                budget: 0,
                startDay: "",
                endDay: "",
                head: "",
                proposer: "",
                test: "",
                projectDay: "",// 立项日期
                moneyFrom: "企业自筹",// 经费来源
                departmentId: "",// 所属部门id
                projectFrom: "自主立项",// 项目来源
                projectType: "",
                isDrafts: false,
            },
            // dayValue: null,
            userLoading: false,
            rules: {
                projectNo: {
                    required: true,
                    trigger: "blur",
                    message: "请输入项目序号",
                },
                projectName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入项目名称",
                },
                startDay: {
                    required: true,
                    message: '请选择研发起始日期',
                    trigger: "change",
                },
                endDay: {
                    required: true,
                    message: '请选择研发结束日期',
                    trigger: "change",
                },
                projectType: {
                    required: true,
                    message: '请选择项目类型',
                    trigger: "change",
                },
                departmentId: {
                    required: true,
                    message: '请选择所属部门',
                    trigger: "change",
                },
            },
            formType: "",
            updateId: 0,
            btnLoading: false,
            checkedUser: [],
            headUserData: [],
            proposerUserData: [],
            testUserData: [],
            initHeadUserData: null,
            initProposerUserData: null,
            initTestUserData: null,
            headLoading: false,
            testLoading: false,
            proposerLoading: false,
            departmentData: [],
            projectTypeData: [
                { id: "1", name: '产品' },
                { id: "2", name: '软件' },
                { id: "3", name: '技术' },
                { id: "4", name: '设备' },
            ],
            projectTypeObj: {
                1: "产品",
                2: "软件",
                3: "技术",
                4: "设备"
            },
            //人员列表
            userTableData: [],
            userCurrentPage: 1,
            userPageSize: 30,
            userTotal: 0,
            userName: "",
            checkedProject: {},
            //导入配置
            showImport: false,
            uploadFilesTitle: "导入研发项目",
            downloadModelFileName: "研发项目模板",
            uploadModelUrl: "project/insertProjectData",
            downloadModelUrl: "project/downloadProjectMoney?isProjectData=true&fileType=0",
            //草稿箱配置
            showDraft: false,
            draftName: "",
            draftTableData: [],
            draftCurrentPage: 1,
            draftTotal: 0,
            draftPageSize: 30,
            draftLoading: false,
            userNameArr: "",
            //导出配置
            loadingBtn: false,
            draftNumber:0,
        };
    },
    mounted() {
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        if (!checkedCompany) {
            this.showCompanyList();
        }
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        //获取年份
        this.getYearData();
        //获取部门
        this.getProjectDepartment();
        //获取项目列表
        this.getList();
        //获取草稿箱数量
        this.searchDraftList();
    },
    methods: {
        /**@method 更新数据 */
        updateData() {
            this.$confirm(`此操作将更新${this.year}年项目生产内容的实际投资、预算, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                updateProjectMoneyCount({
                    year: this.year,
                    pcId: this.checkedCompany.pcId,
                }).then(res => {
                    if (res.code === 200) {
                        this.$message.success("数据更新完成！");
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    if (err.code != 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
        outPutProjectData() {
            this.$message.info("导出中...")
            this.loadingBtn = true;
            let params = {
                pcId: this.checkedCompany.pcId,
            };
            if (this.year == 'all') {
                let yearStr = [];
                for (let year of this.yearData) {
                    yearStr.push(year);
                }
                params.yearStr = yearStr.join(",")
            } else {
                params.yearStr = this.year
            }
            outPutProjectData(params).then(res => {
                this.loadingBtn = false;
                if (res.code == 200) {
                    let link = document.createElement('a');
                    link.href = '/api/crmPc/project/downloadWordFile?fileName=' + res.data;
                    link.click();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.loadingBtn = false;
                if (err.code != 201) {
                    this.$message.error(err.message);
                }
            })
        },
        renewList() {
            this.getList();
            this.getDraftList();
        },
        /**@method 清空人员 */
        emptyUser() {
            this.checkedUser = [];
            this.checkedUserStatus();
        },
        /**@method 快捷添加人员 */
        addUserFun() {
            if (this.userNameArr) {
                let userNameArr = this.userNameArr.replace(/\s/ig, "");
                userNameArr = this.userNameArr.replace(/\,|\，|\；|\;/ig, "、");
                for (let userName of userNameArr.split("、")) {
                    for (let row of this.userTableData) {
                        if (row.userName == userName) {
                            this.selectUser(null, row);
                        }
                    }
                }
                this.userNameArr = "";
                this.checkedUserStatus();
            }
        },
        showDraftFun() {
            this.showDraft = true;
            this.draftName = "";
            this.searchDraftList();
        },
        searchDraftList() {
            this.draftCurrentPage = 1;
            this.getDraftList();
        },
        /**@method 获取列表 */
        async getDraftList() {
            let params = {
                pageSize: this.draftPageSize,
                pageNum: this.draftCurrentPage,
                projectName: this.draftName,
                sortCode: 1,
                isDrafts: true
            };
            if (!this.checkedCompany.pcId) {
                this.draftTableData = [];
                this.draftTotal = 0;
                this.draftNumber=0;
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.draftLoading = true;

                let result = await getProjectDataList(params)
                const { data } = result;
                this.draftLoading = false;
                this.draftTableData = data.pageInfo.list;
                this.draftTotal = data.pageInfo.total;
                if(!this.draftName){//草稿箱数据数量
                    this.draftNumber=data.pageInfo.total;
                }
            } catch (error) {
                if(!this.draftName){//草稿箱数据数量
                    this.draftNumber=0;
                }
                this.draftLoading = false;
                this.draftTableData = [];
            }
        },
        /**@method 切换行 */
        draftSizeChange(val) {
            this.draftPageSize = val;
            this.getDraftList();
        },
        /**@method 切换下一页 */
        draftCurrentChange(val) {
            this.draftCurrentPage = val;
            this.getDraftList();
        },
        /**@method 修改项目状态 */
        updateState(row, state) {
            let params = {
                pdId: row.pdId,
                head: row.head,
                proposer: row.proposer,
                test: row.test,
                members: row.members,
                moneyFrom: row.moneyFrom,
                projectFrom: row.projectFrom,
                isDrafts: state
            };
            setProjectData(params).then(res => {
                this.btnLoading = false;
                if (res.code === 200) {
                    if (state) {
                        this.$message.success("已加入草稿箱！");
                    } else {
                        this.$message.success("已发布到正式！");
                    }
                    this.renewList();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.btnLoading = false;
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        filesSuccess() {
            this.searchFun();
            // this.showImport = false;
        },
        hideModel() {
            this.showImport = false;
        },
        changeTime() {
            this.getUserData("", 'all')
            // this.initHeadUserData = null;
            // this.initProposerUserData = null;
            // this.initTestUserData = null;
            // //获取人员表
            // this.getUserData("", 'head', {
            //     work: "项目经理,总经理,技术总监"
            // });
            // this.getUserData("", 'proposer');
            // this.getUserData("", 'test', {
            //     work: "检查,测试,质检"
            // });
            this.getUserList();
        },
        /**@method 监听立项日期 */
        changePDay() {
            // if (!this.dayValue || this.dayValue.length == 0) {
            //     let mm = new Date(this.formData.projectDay).getMonth() + 1 + 6;
            //     mm = mm > 12 ? mm - 12 : mm;
            //     let yyyy = this.formData.projectDay.slice(0, 4);
            //     // let dd = this.formData.projectDay.slice(-2);
            //     this.dayValue = [this.formData.projectDay, yyyy + "-" + mm + "-28"];
            // }
            let mm = new Date(this.formData.projectDay).getMonth() + 1 + 6;
            mm = mm > 12 ? 12 : mm;
            let yyyy = this.formData.projectDay.slice(0, 4);
            // if (!this.formData.startDay && !this.formData.endDay) {
            this.formData.startDay = this.formData.projectDay;
            this.formData.endDay = yyyy + "-" + mm + "-28";
            this.changeTime();
            // }
        },
        /**@method 获取部门 */
        getProjectDepartment() {
            this.departmentData = [];
            getProjectDepartment({ pcId: this.checkedCompany.pcId }).then(res => {
                if (res.data) {
                    this.departmentData = res.data;
                }
            })
        },
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.searchData.name = "";
            this.searchFun();
            //获取部门
            this.getProjectDepartment();
        },
        delCheckedUser(i) {
            this.$refs.multipleTable.toggleRowSelection(this.userTableData.find(item => {
                return item.puId == this.checkedUser[i].puId;
            }), false);
            this.checkedUser.splice(i, 1)
        },
        getHeadUser(str) {
            this.getUserData(str, 'head');
        },
        getProposerUserData(str) {
            this.getUserData(str, 'proposer');
        },
        getTestUser(str) {
            this.getUserData(str, 'test');
        },
        /**@method 获取人员数据 */
        async getUserData(str, type, query) {
            if (!this.formData.startDay || !this.formData.endDay) {
                return;
            }
            let params = {
                pageSize: 100,
                pageNum: 1,
                userName: str,
                pcId: this.checkedCompany.pcId,
                startDay: this.formData.startDay,
                endDay: this.formData.endDay,
                ...query
            };
            try {
                if (type === 'all') {
                    this.headLoading = true;
                    this.proposerLoading = true;
                    this.testLoading = true;
                    this.headUserData = [];
                    this.proposerUserData = [];
                    this.testUserData = [];
                } else if (type === 'head') {
                    this.headLoading = true;
                    this.headUserData = [];
                } else if (type === 'proposer') {
                    this.proposerLoading = true;
                    this.proposerUserData = [];
                } else if (type === 'test') {
                    this.testLoading = true;
                    this.testUserData = [];
                }
                // if (!str) {
                //     if (type === 'head' && this.initHeadUserData) {
                //         this.headLoading = false;
                //         this.headUserData = this.initHeadUserData;
                //         return;
                //     } else if (type === 'proposer' && this.initProposerUserData) {
                //         this.proposerLoading = false;
                //         this.proposerUserData = this.initProposerUserData;
                //         return;
                //     } else if (type === 'test' && this.initTestUserData) {
                //         this.testLoading = false;
                //         this.testUserData = this.initTestUserData;
                //         return;
                //     }
                // }
                let result = await getProjectUserList(params)
                const { data } = result;
                let objUser = [];
                let ids = [];
                for (let row of data.pageInfo.list) {
                    objUser.push(row);
                    ids.push(row.puId);
                }
                if (type === 'all') {
                    this.headLoading = false;
                    this.proposerLoading = false;
                    this.testLoading = false;
                    this.headUserData = objUser;
                    this.proposerUserData = objUser;
                    this.testUserData = objUser;
                    if (ids.indexOf(this.formData.head) == -1) {
                        this.formData.head = "";
                    } else if (ids.indexOf(this.formData.proposer) == -1) {
                        this.formData.proposer = "";
                    } else if (ids.indexOf(this.formData.test) == -1) {
                        this.formData.test = "";
                    }
                } else if (type === 'head') {
                    this.headLoading = false;
                    this.headUserData = objUser;
                    // if (!this.initHeadUserData) {
                    //     this.initHeadUserData = objUser;
                    // }
                } else if (type === 'proposer') {
                    this.proposerLoading = false;
                    this.proposerUserData = objUser;
                    // if (!this.initProposerUserData) {
                    //     this.initProposerUserData = objUser;
                    // }
                } else if (type === 'test') {
                    this.testLoading = false;
                    this.testUserData = objUser;
                    // if (!this.initTestUserData) {
                    //     this.initTestUserData = objUser;
                    // }
                }
            } catch (error) {
               //-console.log(error);
                if (type === 'all') {
                    this.headLoading = false;
                    this.proposerLoading = false;
                    this.testLoading = false;
                    this.headUserData = [];
                    this.proposerUserData = [];
                    this.testUserData = [];
                    this.formData.head = "";
                    this.formData.proposer = "";
                    this.formData.test = "";
                } else if (type === 'head') {
                    this.headLoading = false;
                    this.headUserData = [];
                } else if (type === 'proposer') {
                    this.proposerLoading = false;
                    this.proposerUserData = [];
                } else if (type === 'test') {
                    this.testLoading = false;
                    this.testUserData = [];
                }
            }
        },
        searchUserTable() {
            this.userCurrentPage = 1;
            this.getUserList();
        },
        /**@method 获取人员列表 */
        async getUserList() {
            if (!this.formData.startDay || !this.formData.endDay) {
                return;
            }
            let params = {
                pageSize: this.userPageSize,
                pageNum: this.userCurrentPage,
                userName: this.userName,
                pcId: this.checkedCompany.pcId,
                startDay: this.formData.startDay,
                endDay: this.formData.endDay
            };
            try {
                this.userLoading = true;
                let result = await getProjectUserList(params)
                const { data } = result;
                this.userLoading = false;
                this.userTableData = data.pageInfo.list;
                this.userTotal = data.pageInfo.total;
                this.checkedUserStatus();
            } catch (error) {
                this.userLoading = false;
                this.userTableData = [];
            }
        },
        /**@method 人员列表换行 */
        userHandleSizeChange(val) {
            this.userPageSize = val;
            this.getUserList();
        },
        /**@method 人员列表分页 */
        userHandleCurrentChange(val) {
            this.userCurrentPage = val;
            this.getUserList();
        },
        selectAllFun(selection) {
            if (selection[0] && selection.length > 0) {
                let ids = [];
                let managerNumber = 0;
                for (let item of this.checkedUser) {
                    if (item.work && item.work.indexOf("项目经理") != -1) {
                        managerNumber++;
                    }
                    ids.push(item.puId);
                }
                for (let row of selection) {
                    if (row && ids.indexOf(row.puId) == -1) {
                        this.checkedUser.push(row);
                    }
                    if (row.work && row.work.indexOf("项目经理") != -1) {
                        managerNumber++;
                    }
                }
                if (managerNumber >= 2) {
                    this.$message.error(`你已选中${managerNumber}位项目经理`);
                }
            } else {
                for (let row of this.userTableData) {
                    for (let index in this.checkedUser) {
                        let item = this.checkedUser[index]
                        if (item.puId == row.puId) {
                            this.checkedUser.splice(index, 1)
                        }
                    }
                }
                this.$refs.multipleTable.clearSelection();
            }
        },
        selectUser(selection, row) {
            let ids = [];
            let managerNumber = 0;
            for (let item of this.checkedUser) {
                //判断是否选中了二个项目经理
                if (item.work && item.work.indexOf("项目经理") != -1) {
                    managerNumber++;
                }
                ids.push(item.puId);
            }
            if (managerNumber >= 1 && row.work && row.work.indexOf("项目经理") != -1 && ids.indexOf(row.puId) == -1) {//判断二个项目经理提示
                this.$message.error(`你已选中${managerNumber + 1}位项目经理`);
            }
            if (ids.indexOf(row.puId) != -1) {
                this.checkedUser.splice(ids.indexOf(row.puId), 1)
            } else {
                this.checkedUser.unshift(row);
            }
        },
        checkedUserFun(id, type) {
            if (!id) {
                return;
            }
            let data = {};
            if (type == 'head') {
                for (let row of this.headUserData) {
                    if (row.puId == id) {
                        data = row;
                        break;
                    }
                }
            } else if (type == 'proposer') {
                for (let row of this.proposerUserData) {
                    if (row.puId == id) {
                        data = row;
                        break;
                    }
                }

            } else if (type == 'test') {
                for (let row of this.testUserData) {
                    if (row.puId == id) {
                        data = row;
                        break;
                    }
                }
            }
            let ids = [];
            for (let item of this.checkedUser) {
                ids.push(item.puId);
            }
            if (ids.indexOf(id) == -1) {
                this.checkedUser.unshift(data);
            }
            this.checkedUserStatus();
        },
        /**@method 删除项目 */
        delProject(row) {
            this.$confirm("确定要继续删除这条研发项目", "删除研发项目", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                let params = {
                    isDel: 1,
                    pdId: row.pdId
                }
                setProjectData(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('删除完成');
                        this.showModel = false;
                        this.searchData.name = "";
                        this.renewList();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
        /**@method 获取最近五年 */
        getYearData() {
            let yearData = [];
            let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
            this.yearData = yearData;
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage = 1;
            this.getList();
        },
        /**@method 提交新增/修改数据 */
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let startDay = new Date(this.formData.startDay).getTime();
                    let endDay = new Date(this.formData.endDay).getTime();
                    if (startDay > endDay) {
                        this.$message.error("研发日期开始时间不能大于结束时间");
                        return;
                    }
                    this.btnLoading = true;
                    let membersIds = [];
                    for (let row of this.checkedUser) {//获取人员id
                        membersIds.push(row.puId);
                    }
                    let params = {
                        ...this.formData,
                        members: membersIds.join(","),
                        pcId: this.checkedCompany.pcId,
                        // startDay: this.dayValue[0],
                        // endDay: this.dayValue[1],
                    }
                    let message = "新增完成";
                    if (this.formType === 'update') {
                        params.pdId = this.updateId;
                        message = "编辑完成";
                    }
                    setProjectData(params).then(res => {
                        this.btnLoading = false;
                        if (res.code === 200) {
                            this.$message.success(message);
                            this.showModel = false;
                            this.renewList();
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        this.btnLoading = false;
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                projectName: this.searchData.name,
                sortCode: 1,
                isDrafts: false
            };
            if (this.year != 'all') {
                params.selectYear = this.year
            }
            if (!this.checkedCompany.pcId) {
                this.tableData = [];
                this.total = 0
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;

                let result = await getProjectDataList(params)
                const { data } = result;
                this.loading = false;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增研发项目";
            this.formType = "add";
            this.formData = {
                projectNo: "RD",
                projectName: "的研究开发",
                money: 10,
                budget: 0,
                startDay: "",
                endDay: "",
                head: "",
                proposer: "",
                test: "",
                projectDay: "",// 立项日期
                moneyFrom: "企业自筹",// 经费来源
                departmentId: this.departmentData.length > 0 ? this.departmentData[0].departmentId : "",// 所属部门id
                projectFrom: "自主立项",// 项目来源
                projectType: "",
            };
            if (this.year != 'all') {
                let n = this.total + 1;
                this.formData.projectNo = n < 10 ? "RD0" + n : "RD" + n;
            } else {
                this.formData.projectNo = "RD"
            }
            // this.dayValue = null;
            this.updateId = 0;
            this.checkedUser = [];
            this.userPageSize = 30;
        },
        toCreateWord(row) {
            // this.$router.push('/createWord');
            this.checkedProject = JSON.parse(JSON.stringify(row));
            this.$refs.projectDevelop.showModel(true);
        },
        /**@method 编辑
         * @param {Object} row - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.modelTitle = "编辑项目";
            this.formType = "update";
            this.updateId = row.pdId;
            this.userNameArr="";
            // this.dayValue = [row.startDay, row.endDay];
            this.formData = {
                projectName: row.projectName,
                projectNo: row.projectNo,
                money: Number(row.money),
                budget: Number(row.budget),
                startDay: row.startDay,
                endDay: row.endDay,
                head: row.head,
                proposer: row.proposer,
                test: row.test,
                projectDay: row.projectDay,// 立项日期
                moneyFrom: row.moneyFrom,// 经费来源
                departmentId: row.departmentId,// 所属部门id
                projectFrom: row.projectFrom,// 项目来源
                projectType: String(row.projectType),
            };
            this.userPageSize = 30;
            this.changeTime();
            // this.getUserList();
            // if (row.head) {
            //     this.getHeadUser(row.headName);
            // } else {
            //     this.getHeadUser();
            // }
            // if (row.proposer) {
            //     this.getProposerUserData(row.proposerName);
            // } else {
            //     this.getProposerUserData();
            // }
            // if (row.test) {
            //     this.getTestUser(row.testName);
            // } else {
            //     this.getTestUser();
            // }
            this.checkedUser = row.projectUserList ? row.projectUserList : [];
            this.checkedUserStatus();

        },
        checkedUserStatus() {
            this.$nextTick(() => {
                if (this.checkedUser.length > 0) {
                    for (let row of this.checkedUser) {
                        this.$refs.multipleTable.toggleRowSelection(this.userTableData.find(item => {
                            return item.puId == row.puId;
                        }), true);
                    }
                } else {
                    for (let row of this.userTableData) {
                        this.$refs.multipleTable.toggleRowSelection(row, false);
                    }
                }
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
    cursor: pointer;
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>